import React from 'react';
import { Accordion, Card, useAccordionButton } from 'react-bootstrap';
import SquareIcon from '../../static/assets/service-digitalApplicationWebSquareIcon.svg';

const WebRows = ({ title, hr, src, description, indexKey }) => {
  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log('totally custom!')
    );

    return (
      <button
        type='button'
        style={{
          backgroundColor: 'transparent',
          border: 'none',
        }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  };
  return (
    <>
      <Card style={{ backgroundColor: 'transparent', border: 'none' }}>
        <div className='accordionV2-container-data-group'>
          <Card.Header
            style={{ backgroundColor: 'transparent', border: 'none' }}
          >
            <CustomToggle eventKey={indexKey}>
              <div style={{ display: 'flex', gap: '30px' }}>
                <SquareIcon />

                <h1 className='accordionV2-container-data-group-head'>
                  {title}
                </h1>
              </div>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={indexKey}>
            <div className='accordionV2-container-data-group-body'>
              <p className='accordionV2-container-data-group-body-text'>
                {description}
              </p>
            </div>
          </Accordion.Collapse>
        </div>
      </Card>
      {hr && <hr className='accordionV2-container-data-hr' />}
    </>
  );
};

const AccordionComponentV2 = ({ questions, title, description }) => {
  return (
    <div className='accordionV2-container'>
      <div className='accordionV2-container-head'>
        <h1 className='accordionV2-container-head-title'>{title}</h1>
        <p className='accordionV2-container-head-text'>{description}</p>
      </div>
      <div className='accordionV2-container-data'>
        <Accordion defaultActiveKey='0'>
          {questions.map((item, i) => (
            <WebRows
              title={item.title}
              hr={item.hr}
              src={item.src}
              description={item.description}
              indexKey={i}
            />
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default AccordionComponentV2;
