import React, { useState } from 'react';
const CardsComponent = props => {
  const { cards, title, description, showCards = false, description2 } = props;
  const [showAllCards, setShowAllCards] = useState(
    showCards ? showCards : false
  );

  const toggleCards = () => setShowAllCards(!showAllCards);
  return (
    <div
      className='cards-section'
      style={{ marginBottom: showAllCards ? '95px' : '-400px' }}
    >
      <div className='full-container'>
        <h2
          className='cards-section__heading'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className='cards-section__intro'
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '50px',
          }}
        >
          <div
            className={`cards-section__wrapper row ${
              showAllCards ? 'show-all-cards' : ''
            }`}
          >
            {cards &&
              cards.map((card, i) => (
                <div
                  key={card.title}
                  className={`${i % 2 === 0 ? 'margin-bottom' : 'margin-top'}  
                card-wrapper                         
                `}
                >
                  <div className='cards-section__card'>
                    <img src={card.icon} className='cards-section__icon' />
                    <p
                      className='cards-section__title'
                      dangerouslySetInnerHTML={{ __html: card.title }}
                    />
                    <p
                      className='cards-section__description'
                      dangerouslySetInnerHTML={{ __html: card.description }}
                    />
                  </div>
                </div>
              ))}
          </div>
          {!showCards && (
            <button onClick={toggleCards} className='cards-section__button'>
              {showAllCards ? 'Show less' : 'See all'}
            </button>
          )}
        </div>
        <p
          className='cards-section__intro'
          style={{ marginTop: '80px', lineHeight: '38px' }}
          dangerouslySetInnerHTML={{ __html: description2 }}
        />
      </div>
      <div
        className={`cards-section__blur-overlay ${
          showAllCards ? '' : 'blur-overlay'
        }`}
      ></div>
    </div>
  );
};

export default CardsComponent;
